.container {
  align-self: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s;
  width: 100%;
  padding-right: 2rem;
  padding-left: 2rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mapContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  /* flex-grow: 1; */
  flex-direction: row;
  width: 100%;
  animation: fadeIn 0.5s;
  /* padding: 10%; */
}

.directions {
  grid-column: 1 / 2;
  /* width: fit-content; */
}

.emeraldMap {
  width: 100%;
}

.googleMap {
  grid-column: 2 / 2;
  width: 100%;
  /* width: fit-content; */
}

@media only screen and (max-width: 500px) {
  .container {
    padding: 2%;
    width: 100%;
  }
  .directions {
    height: fit-content;
  }
  .mapContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    animation: fadeIn 0.5s;
    /* padding: 10%; */
  }
  .googleMap {
    grid-column: 1 / 2;
    width: 100%;
    /* width: fit-content; */
  }
}
