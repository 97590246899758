.container {
  padding-top: 20px;
  align-self: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  animation: fadeIn 0.5s;
  width: 100%;
}

.list {
  width: 30vw;
}

.list div {
  display: flex;
  margin-bottom: 0.75rem;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 500px) {
  .container {
    padding: 2%;
    width: 100%;
  }
  .list {
    width: 60vw;
  }
}
