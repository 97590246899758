.container {
  padding-top: 20px;
  align-self: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  justify-content: flex-start;
  animation: fadeIn 0.5s;
  width: 100%;
}

.list li {
  margin-bottom: 0.2rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    padding: 2%;
    width: 100%;
  }
}
