.header {
  /* background-image: url('./images/enchantments.jpg'); */
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  grid-template-rows: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  width: 100vw;
  height: auto;
  display: grid;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.myFace {
  z-index: -1;
  /* background-image: url('./images/enchantments.jpg'); */
  /* grid-column-start: 1 / span 5;
  grid-row-start: 1 / span 5; */
  position: relative;
  height: 100vh;

  /* align-self: center; */
  /* justify-self: center; */
}

@media only screen and (min-width: 1131px) {
  .myFace {
    z-index: -1;
    /* grid-column-start: 1 / span 5;
    grid-row-start: 1 / span 5; */
    position: relative;
    width: 100vw;
    height: auto;

    /* align-self: center; */
    /* justify-self: center; */
  }
}
.overlap {
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  grid-template-rows: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10% 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: grid;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-items: center;
}
.flexbox {
  display: flex;
  flex-direction: column;
}

.adrienneandnate {
  text-align: center;
  white-space: pre-line;

  grid-column: 3 / 10;
  grid-row: 2;
  font-size: xxx-large;
  font-family: var(--title-font);
  font-weight: 1000;
  color: var(--light-text-color);
  text-shadow: -1px -1px 0 var(--stroke-color), 1px -1px 0 var(--stroke-color),
    -1px 1px 0 var(--stroke-color), 1px 1px 0 var(--stroke-color);
  margin-bottom: 0;
}

.title {
  /* grid-column: 4 / 10; */
  /* grid-row: 5; */
  font-size: xx-large;
  font-family: var(--title-font);
  font-weight: 1000;
  color: var(--light-text-color);

  /* font-family: var(--font); */
  font-style: normal;
  /* font-weight: 400; */
  color: var(--light-text-color);
  margin: 0;
  margin-left: 2vw;
  text-shadow: -1px -1px 0 var(--stroke-color), 1px -1px 0 var(--stroke-color),
    -1px 1px 0 var(--stroke-color), 1px 1px 0 var(--stroke-color);
}

.flexboxContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  height: 4em;
  grid-column: 4 /9;
  grid-row: 3 / 7;
  font-size: x-large;
  white-space: nowrap;
  font-weight: 1000;
  text-shadow: -1px -1px 0 var(--stroke-color), 1px -1px 0 var(--stroke-color),
    -1px 1px 0 var(--stroke-color), 1px 1px 0 var(--stroke-color);
  text-decoration: underline;
}
@media only screen and (max-width: 500px) {
  .tabs {
    display: flex;
    justify-content: flex-start;
    grid-column: 1 /9;
    grid-row: 7 / 7;
    font-size: small;
    white-space: wrap;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.25);
    border: 1px black;
    /* background-color: var(--stroke-color); */
    height: fit-content;
    width: 100%;
  }
}

.activeTab {
  text-decoration: none;
  cursor: pointer;
  /* background: var(--active-tab-color); */
  border-radius: 10%;
  margin-right: 0.3rem;
  /* text settings */
  box-shadow: 1px 1px 1px 1px var(--stroke-color);
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  font-family: var(--font);

  text-align: center;
  color: var(--black-text-color);

  text-shadow: 0px 0px 0 var(--stroke-color), 0px 0px 0 var(--stroke-color),
    0px 1px 0 var(--stroke-color), 0px 1px 0 var(--stroke-color);
}

.tab {
  text-decoration: none;
  cursor: pointer;
  font-weight: 1000;
  font-size: larger;
  /* background: var(--primary-color); */
  border-radius: 10%;
  margin-right: 0.3rem;
  /* text settings */

  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  font-family: var(--font);

  text-align: center;
  color: var(--light-text-color);
}
