.mapIcon {
  width: 1em;
  align-self: flex-end;
}

.link:link {
  color: var(--active-tab-color);
  background-color: transparent;
}
.link:visited {
  color: var(--moab-red);
  background-color: transparent;
}
