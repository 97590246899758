.container {
  align-self: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s;
  width: 100%;
  padding: 1%;
  margin-top: 0;
}

.OurAdventure {
  margin-top: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.photos {
  padding: 5rem;
  padding-top: 1rem;
}

@media only screen and (max-width: 500px) {
  .container {
    padding: 2%;
    width: 100%;
  }
  .photos {
    padding: 0;
    width: 100%;
    height: 80vh;
  }
}

.ptag {
  margin: 0.2rem;
}
