@import 'https://fonts.googleapis.com/css?family=Work+Sans:400,700&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap';
*,
:before,
:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  display: flex;
  flex-flow: column;
}
p {
  animation: fadeIn 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

h6 {
  color: red;
}

h4 {
  font-weight: 500;
}
p {
  font-weight: 600;
}
li {
  font-weight: 600;
}
h1 {
  /* font-family: var(--font); */
  font-weight: 1000;
  font-size: xx-large;
}

:root {
  /* background-color: #fcf8f1; */
  font-family: Work Sans, sans-serif;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: var(--light-text-color);

  --gradient-color-1: #fefefe;
  --gradient-color-2: #e7e7e7;
  background-image: linear-gradient(
    to bottom right,
    var(--gradient-color-1),
    var(--gradient-color-2)
  );

  --primary-color: #d9d9d9;
  --black-text-color: #d6c9c9;
  --light-text-color: #000000;

  --active-tab-color: #064f09;
  --moab-red: #cd6430;

  --stroke-color: rgb(127, 127, 127);
  --font: cursive;
  --title-font: Parisienne;
}
